import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`En temps humit aquest bolet presenta una capa mucilaginosa al capell. Aquest primerament és convex i tancat i s’obre fins acabar tenint el marge alçat. Pot fer fins a 9 m de diàmetre i és de color marró grisenc a color mel amb el marge més clar o quasi blanc. Les làmines són blanques adnades o decurrents, separades i arquejades. El peu és llarg, un poc gruixut, blanquinós a la part superior i cobert d’esquames molt viscoses a la part inferior. Les espores són blanques en massa, hialines, llises i el·lipsoïdals, de 9-13 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      